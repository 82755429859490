export const ownerMenuItems = [
    {
        id: 2,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: "/",
    },
    {
        id: 19,
        label: "Pending Payments",
        icon: "ri-currency-line",
        link: "/pending-payments",
    },
    {
        id: 3,
        label: "Circulars",
        icon: "ri-book-2-line",
        link: "/circulars",
    },
    {
        id: 4,
        label: "Mortgage",
        icon: "ri-home-smile-2-line",
        link: "/mortgage",
    },
    {
        id: 5,
        label: "Amendment",
        icon: "ri-file-paper-2-line",
        link: "/amendment",
    },
    {
        id: 6,
        label: "Maintenance",
        icon: "ri-file-settings-line",
        link: "/new-maintenance",
    },
    {
        id: 7,
        label: "Immigration",
        icon: "ri-flight-takeoff-line",
        link: "/new-immigration",
    },
    {
        id: 8,
        label: "Title Deed",
        icon: "ri-pages-fill",
        link: "/new-titledeed",
    },
    {
        id: 9,
        label: "Interim Registration",
        icon: "ri-links-fill",
        link: "/new-interim",
    },
    {
        id: 10,
        label: "Home Improvement",
        icon: "ri-home-gear-fill",
        link: "/home-improvement",
    },
    {
        id: 11,
        label: "Landscape",
        icon: "ri-plant-line",
        link: "/landscaping",
    },
    {
        id: 12,
        label: "Security Deposit Refund",
        icon: "ri-refund-2-line",
        link: "/deposit-refund",
    },
    {
        id: 13,
        label: "Noc Revalidation",
        icon: "ri-hand-coin-line",
        link: "/noc-revalidation",
    },
    {
        id: 14,
        label: "Villa As-Built Drawing",
        icon: "ri-community-line",
        link: "/built-drawings",
    },
    {
        id: 15,
        label: "Trash Bin",
        icon: "ri-delete-bin-7-fill",
        link: "/trash-bin",
    },

    {
        id: 17,
        label: "Barrier Remote Control",
        icon: "ri-remote-control-2-line",
        link: "/barrier-control",
    },
    {
        id: 18,
        label: "Refernce Docs",
        icon: "ri-sticky-note-line",
        link: "/",
    },
];
