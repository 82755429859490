export const collectionMenuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: "/",
    },
    {
        id: 3,
        label: "Projects",
        icon: "ri-pencil-ruler-2-line",
        link: "/projects",
    },

    {
        id: 4,
        label: "Payment Links",
        icon: "ri-exchange-dollar-fill",
        link: "/",
        subItems: [
            {
                id: 5,
                label: "Create Payment Link",
                link: "/payment-links",
            },
            {
                id: 6,
                label: "List",
                link: "/payment-links-list",
            },
        ],
    },
    {
        id: 2,
        label: "Circulars",
        icon: "ri-book-2-line",
        link: "/circulars",
    },
    {
        id: 7,
        label: "Visitor Logs",
        icon: "ri-user-line",
        subItems: [
            {
                id: 8,
                label: "Create New Log",
                link: "/create-log",
            },
            {
                id: 9,
                label: "List",
                link: "/log-list",
            },
        ],
    },
];
