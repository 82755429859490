<script>
// import simplebar from "simplebar-vue";
import i18n from "../i18n";
import { layoutComputed } from "@/state/helpers";

export default {
    data() {
        return {
            current_language: "en",
        };
    },
    mounted() {},
    computed: {
        ...layoutComputed,
        userInformation() {
            // return JSON.parse(localStorage.user_info);
            return this.$store.getters["apidata/getUserInfo"];
        },
        profilePic() {
            // return localStorage.profile_pic;
            return this.$store.getters["apidata/getProfileImage"];
        },

        sidebarCollapsed() {
            return this.$store.getters["apidata/getSidebarStatus"];
        },
    },
    // components: { simplebar },
    methods: {
        navigateToProfile() {
            this.$router.push({
                path: `/profile/0`,
            });
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },

        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */ !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        setLanguage(locale) {
            i18n.locale = locale;
            this.current_language = i18n.locale;
        },
    },
};
</script>

<template>
    <header
        class="page_topbar"
        :class="sidebarCollapsed ? 'page_topbar_sidebar' : ''"
    >
        <div class="navbar-header">
            <div class="d-flex">
                <button
                    @click="toggleMenu"
                    type="button"
                    class="btn btn-sm px-3 font-size-24 header-item waves-effect"
                    id="vertical-menu-btn"
                >
                    <i class="ri-menu-2-line align-middle"></i>
                </button>

                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a href="#" class="logo-dark">
                        <span class="logo-sm">
                            <img
                                src="@/assets/images/logo.png"
                                alt
                                class="logo"
                            />
                        </span>
                        <span class="logo-lg">
                            <img
                                src="@/assets/images/logo.png"
                                alt
                                class="logo"
                            />
                        </span>
                    </a>

                    <a href="#" class="logo logo-light">
                        <span class="logo-sm">
                            <img
                                src="@/assets/images/logo.png"
                                alt
                                class="logo"
                            />
                        </span>
                        <span class="logo-lg">
                            <img
                                src="@/assets/images/logo.png"
                                alt
                                class="logo"
                            />
                        </span>
                    </a>
                </div>

                <!-- <div class="navbar__text">
                    <h5>Western Residence North</h5>
                    <p>Customer Service Department</p>
                </div> -->
                <!-- App Search-->
            </div>

            <div class="d-flex">
                <!-- Profile dropdown -->
                <b-dropdown
                    right
                    variant="black"
                    toggle-class="header-item"
                    class="d-inline-block user-dropdown"
                >
                    <template v-slot:button-content>
                        <img
                            v-if="!profilePic"
                            class="rounded-circle header-profile-user"
                            src="../assets/images/users/profilepic.png"
                            alt="Header Avatar"
                        />
                        <img
                            v-if="profilePic"
                            class="rounded-circle header-profile-user"
                            :src="`data:image/png;base64,${profilePic}`"
                            alt="Header Avatar"
                        />

                        <span class="d-none d-xl-inline-block ml-1">{{
                            userInformation.name
                        }}</span>
                        <i
                            class="mdi mdi-chevron-down d-none d-xl-inline-block"
                        ></i>
                    </template>
                    <!-- item-->
                    <a class="dropdown-item" @click="navigateToProfile()">
                        <i class="ri-user-line align-middle mr-1"></i>
                        {{ $t("navbar.dropdown.kevin.list.profile") }}
                    </a>

                    <!-- <a class="dropdown-item d-block" href="#">
                        <span class="badge badge-success float-right mt-1"
                            >11</span
                        >
                        <i class="ri-settings-2-line align-middle mr-1"></i>
                        {{ $t("navbar.dropdown.kevin.list.settings") }}
                    </a> -->
                    <!-- <a class="dropdown-item" href="#">
                        <i class="ri-lock-unlock-line align-middle mr-1"></i>
                        {{ $t("navbar.dropdown.kevin.list.lockscreen") }}
                    </a> -->
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" href="/login">
                        <i
                            class="ri-shut-down-line align-middle mr-1 text-danger"
                        ></i>
                        {{ $t("navbar.dropdown.kevin.list.logout") }}
                    </a>
                </b-dropdown>

                <div class="dropdown d-none d-lg-inline-block ml-1">
                    <button
                        type="button"
                        class="btn header-item noti-icon waves-effect"
                        @click="initFullScreen"
                    >
                        <i class="ri-fullscreen-line"></i>
                    </button>
                </div>

                <!-- notification dropdown -->
                <!-- <b-dropdown
                    right
                    menu-class="dropdown-menu-lg p-0"
                    toggle-class="header-item noti-icon"
                    variant="black"
                >
                    <template v-slot:button-content>
                        <i class="ri-notification-3-line"></i>
                        <span class="noti-dot"></span>
                    </template>
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">
                                    {{
                                        $t("navbar.dropdown.notification.text")
                                    }}
                                </h6>
                            </div>
                            <div class="col-auto">
                                <a href="#!" class="small">{{
                                    $t("navbar.dropdown.notification.subtext")
                                }}</a>
                            </div>
                        </div>
                    </div>
                    <simplebar style="max-height: 230px">
                        <a href class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span
                                        class="avatar-title bg-primary rounded-circle font-size-16"
                                    >
                                        <i class="ri-shopping-cart-line"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">
                                        {{
                                            $t(
                                                "navbar.dropdown.notification.order.title"
                                            )
                                        }}
                                    </h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.order.text"
                                                )
                                            }}
                                        </p>
                                        <p class="mb-0">
                                            <i
                                                class="mdi mdi-clock-outline"
                                            ></i>
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.order.time"
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href class="text-reset notification-item">
                            <div class="media">
                                <img
                                    src="@/assets/images/users/avatar-3.jpg"
                                    class="mr-3 rounded-circle avatar-xs"
                                    alt="user-pic"
                                />
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">
                                        {{
                                            $t(
                                                "navbar.dropdown.notification.james.title"
                                            )
                                        }}
                                    </h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.james.text"
                                                )
                                            }}
                                        </p>
                                        <p class="mb-0">
                                            <i
                                                class="mdi mdi-clock-outline"
                                            ></i>
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.james.time"
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span
                                        class="avatar-title bg-success rounded-circle font-size-16"
                                    >
                                        <i class="ri-checkbox-circle-line"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">
                                        {{
                                            $t(
                                                "navbar.dropdown.notification.item.title"
                                            )
                                        }}
                                    </h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.item.text"
                                                )
                                            }}
                                        </p>
                                        <p class="mb-0">
                                            <i
                                                class="mdi mdi-clock-outline"
                                            ></i>
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.item.time"
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href class="text-reset notification-item">
                            <div class="media">
                                <img
                                    src="@/assets/images/users/avatar-4.jpg"
                                    class="mr-3 rounded-circle avatar-xs"
                                    alt="user-pic"
                                />
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">
                                        {{
                                            $t(
                                                "navbar.dropdown.notification.salena.title"
                                            )
                                        }}
                                    </h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.salena.text"
                                                )
                                            }}
                                        </p>
                                        <p class="mb-0">
                                            <i
                                                class="mdi mdi-clock-outline"
                                            ></i>
                                            {{
                                                $t(
                                                    "navbar.dropdown.notification.salena.time"
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </simplebar>
                    <div class="p-2 border-top">
                        <a
                            class="btn btn-sm btn-link font-size-14 btn-block text-center"
                            href="javascript:void(0)"
                        >
                            <i class="mdi mdi-arrow-right-circle mr-1"></i>
                            {{ $t("navbar.dropdown.notification.button") }}
                        </a>
                    </div>
                </b-dropdown> -->
            </div>
        </div>
    </header>
</template>

<style scoped>
body[data-sidebar="dark"] .navbar-brand-box {
    background-color: white;
}

/* atleast 992px */
@media screen and (min-width: 992px) {
    .page_topbar {
        top: 0;
        right: 0;
        z-index: 1002;
        margin-left: 240px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    }
}

.page_topbar_sidebar {
    top: 0;
    right: 0;
    z-index: 1002;
    margin-left: 70px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
}
/* atmost 600px */
@media screen and (max-width: 992px) {
    .page_topbar {
        top: 0;
        right: 0;
        z-index: 1002;
        margin-left: 0px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    }
}
.logo {
    height: 39px !important;
}
</style>
