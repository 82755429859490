<script>
import simplebar from "simplebar-vue";
import { layoutComputed } from "@/state/helpers";
import Swal from "sweetalert2";

import MetisMenu from "metismenujs/dist/metismenujs";

import { menuItems } from "./menu";
// import { staffMenuItems } from "./staffmenu";
import { ownerMenuItems } from "./ownerMenu";
import { tenantMenuItems } from "./tenantMenu";
import { collectionMenuItems } from "./collectionMenu";
export default {
    components: {
        simplebar,
    },
    props: {
        isCondensed: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // userInformation: null,
            userRole: null,
            isAdmin: false,
            isWorkAssigner: false,
        };
    },
    computed: {
        ...layoutComputed,
        userInformation() {
            return this.$store.getters["apidata/getUserInfo"];
        },
        profilePic() {
            return this.$store.getters["apidata/getProfileImage"];
        },

        menuItems() {
            let items = null;
            if (localStorage.cs_user_role === "Administrator") {
                items = menuItems;
            } else if (localStorage.cs_user_role === "Owner") {
                items = ownerMenuItems;
            } else if (localStorage.cs_user_role === "Tenant") {
                items = tenantMenuItems;
            } else if (localStorage.cs_user_role === "Collection") {
                items = collectionMenuItems;
            } else {
                items = menuItems;
            }
            return items;
        },
        sidebarCollapsed() {
            return this.$store.getters["apidata/getSidebarStatus"];
        },
    },
    mounted: function () {
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu("#side-menu");
        this.userRole = localStorage.cs_user_role;
        // this.userInformation = JSON.parse(localStorage.user_info);
        console.log("the user role is ", localStorage.cs_user_role);
        var links = document.getElementsByClassName("side-nav-link-ref");
        var matchingMenuItem = null;
        for (var i = 0; i < links.length; i++) {
            if (window.location.pathname === links[i].pathname) {
                matchingMenuItem = links[i];
                break;
            }
        }

        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active");
            var parent = matchingMenuItem.parentElement;

            /**
             * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
             * We should come up with non hard coded approach
             */
            if (parent) {
                parent.classList.add("mm-active");
                const parent2 = parent.parentElement.closest("ul");
                if (parent2 && parent2.id !== "side-menu") {
                    parent2.classList.add("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.add("mm-active");
                        var childAnchor = parent3.querySelector(".has-arrow");
                        var childDropdown =
                            parent3.querySelector(".has-dropdown");
                        if (childAnchor) childAnchor.classList.add("mm-active");
                        if (childDropdown)
                            childDropdown.classList.add("mm-active");

                        const parent4 = parent3.parentElement;
                        if (parent4 && parent4.id !== "side-menu") {
                            parent4.classList.add("mm-show");
                            const parent5 = parent4.parentElement;
                            if (parent5 && parent5.id !== "side-menu") {
                                parent5.classList.add("mm-active");
                                const childanchor =
                                    parent5.querySelector(".is-parent");
                                if (childanchor && parent5.id !== "side-menu") {
                                    childanchor.classList.add("mm-active");
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        /**
         * Returns true or false if given menu item has child or not
         * @param item menuItem
         */
        hasItems(item) {
            return item.subItems !== undefined
                ? item.subItems.length > 0
                : false;
        },
        onRoutechange() {
            setTimeout(() => {
                const currentPosition =
                    document.getElementsByClassName("mm-active")[0].offsetTop;
                if (currentPosition > 400)
                    this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
                        currentPosition + 200;
            }, 300);
        },

        toggleMenu() {
            this.$parent.toggleMenu();
        },
        logout() {
            Swal.fire({
                title: "Logout from your account ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, logout of my account!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("apidata/removeProfilePic");
                    this.$store.replaceState({
                        apidata: {},
                    });
                    this.$router.push("/login");

                    setTimeout(() => {
                        location.reload();
                    }, 500);
                }
            });
        },
    },
    watch: {
        $route: {
            handler: "onRoutechange",
            immediate: true,
            deep: true,
        },
        type: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "dark":
                            document.body.setAttribute("data-sidebar", "dark");
                            document.body.removeAttribute("data-topbar");
                            document.body.removeAttribute("data-sidebar-size");
                            break;
                        case "light":
                            document.body.setAttribute("data-topbar", "dark");
                            document.body.removeAttribute("data-sidebar");
                            document.body.removeAttribute("data-sidebar-size");
                            document.body.classList.remove("vertical-collpsed");
                            break;
                        case "compact":
                            document.body.setAttribute(
                                "data-sidebar-size",
                                "small"
                            );
                            document.body.setAttribute("data-sidebar", "dark");
                            document.body.classList.remove("vertical-collpsed");
                            document.body.removeAttribute(
                                "data-topbar",
                                "dark"
                            );
                            break;
                        case "icon":
                            document.body.setAttribute(
                                "data-keep-enlarged",
                                "true"
                            );
                            document.body.classList.add("vertical-collpsed");
                            document.body.setAttribute("data-sidebar", "dark");
                            document.body.removeAttribute(
                                "data-topbar",
                                "dark"
                            );
                            break;
                        case "colored":
                            document.body.setAttribute(
                                "data-sidebar",
                                "colored"
                            );
                            document.body.removeAttribute("data-keep-enlarged");
                            document.body.classList.remove("vertical-collpsed");
                            document.body.removeAttribute("data-sidebar-size");
                            break;
                        default:
                            document.body.setAttribute("data-sidebar", "dark");
                            break;
                    }
                }
            },
        },
        width: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "boxed":
                            document.body.setAttribute(
                                "data-layout-size",
                                "boxed"
                            );
                            break;
                        case "fluid":
                            document.body.setAttribute(
                                "data-layout-mode",
                                "fluid"
                            );
                            document.body.removeAttribute("data-layout-size");
                            break;
                        default:
                            document.body.setAttribute(
                                "data-layout-mode",
                                "fluid"
                            );
                            break;
                    }
                }
            },
        },
    },
};
</script>
<template>
    <div class="vertical-menu">
        <simplebar class="h-100" ref="currentMenu" id="my-element">
            <img
                v-if="!profilePic"
                src="../assets/images/users/profilepic.png"
                alt=""
                class="profile_pic"
            />
            <img
                v-if="profilePic"
                class="profile_pic"
                :src="`data:image/png;base64,${profilePic}`"
                alt="Header Avatar"
            />
            <p class="role">{{ userRole }}</p>
            <h4 class="role" v-if="userInformation">
                {{ userInformation.name }}
            </h4>

            <div class="sidebar_account_actions">
                <div>
                    <div class="sidebar_account_icons">
                        <router-link :to="{ path: '/profile/3' }">
                            <img
                                src="../assets/images/layouts/key.png"
                                alt=""
                                class="icon_image"
                            />
                        </router-link>
                        <p class="iconText">Password</p>
                    </div>
                </div>

                <div>
                    <div class="sidebar_account_icons">
                        <img
                            src="../assets/images/layouts/logout.png"
                            alt=""
                            class="icon_image"
                            @click="logout()"
                        />
                        <p class="iconText">Logout</p>
                    </div>
                </div>
            </div>

            <!--- Sidemenu -->
            <div id="sidebar-menu">
                <!-- Left Menu Start -->
                <ul class="metismenu list-unstyled" id="side-menu">
                    <template>
                        <div v-for="item in menuItems" :key="item.id">
                            <li
                                class="menu-title"
                                v-if="item.isTitle"
                                :key="item.id"
                            >
                                {{ $t(item.label) }}
                            </li>

                            <!--end Layouts menu -->
                            <li
                                v-if="!item.isTitle && !item.isLayout"
                                :key="item.id"
                            >
                                <a
                                    v-if="hasItems(item)"
                                    href="javascript:void(0);"
                                    class="is-parent"
                                    :class="{
                                        'has-arrow': !item.badge,
                                        'has-dropdown': item.badge,
                                    }"
                                >
                                    <i
                                        :class="`bx ${item.icon}`"
                                        v-if="item.icon"
                                    ></i>
                                    <span v-if="!sidebarCollapsed">{{
                                        $t(item.label)
                                    }}</span>
                                    <span
                                        :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                                        v-if="item.badge"
                                        >{{ $t(item.badge.text) }}</span
                                    >
                                </a>

                                <router-link
                                    :to="item.link"
                                    v-if="!hasItems(item)"
                                    class="side-nav-link-ref"
                                >
                                    <i
                                        :class="`bx ${item.icon}`"
                                        v-if="item.icon"
                                    ></i>
                                    <span v-if="!sidebarCollapsed">{{
                                        $t(item.label)
                                    }}</span>
                                    <span
                                        :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                                        v-if="item.badge"
                                        >{{ $t(item.badge.text) }}</span
                                    >
                                </router-link>

                                <ul
                                    v-if="hasItems(item)"
                                    class="sub-menu"
                                    aria-expanded="false"
                                >
                                    <li
                                        v-for="(
                                            subitem, index
                                        ) of item.subItems"
                                        :key="index"
                                    >
                                        <router-link
                                            :to="subitem.link"
                                            v-if="!hasItems(subitem)"
                                            class="side-nav-link-ref"
                                            >{{
                                                $t(subitem.label)
                                            }}</router-link
                                        >
                                        <a
                                            v-if="hasItems(subitem)"
                                            class="side-nav-link-a-ref has-arrow"
                                            href="javascript:void(0);"
                                            >{{ subitem.label }}</a
                                        >
                                        <ul
                                            v-if="hasItems(subitem)"
                                            class="sub-menu mm-collapse"
                                            aria-expanded="false"
                                        >
                                            <li
                                                v-for="(
                                                    subSubitem, index
                                                ) of subitem.subItems"
                                                :key="index"
                                            >
                                                <router-link
                                                    :to="subSubitem.link"
                                                    class="side-nav-link-ref"
                                                    @click="toggleMenu"
                                                    >{{
                                                        $t(subSubitem.label)
                                                    }}</router-link
                                                >
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </div>
                    </template>
                </ul>
            </div>
        </simplebar>
    </div>
</template>

<style scoped>
.profile_pic {
    height: 50%;
    width: 50%;
    border-radius: 50%;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    border: 2px solid #c59220;
}
.role {
    text-align: center;
    color: white;
    font-size: 13.3px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}
.iconText {
    color: white;
    font-size: 13.3px;
    font-family: "Inter", sans-serif;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
}
.sidebar_account_icons {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sidebar_account_actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 13px;
}
.vertical-menu {
    /* height: 100vh; */
    top: 0px;
}
.icon_image {
    height: 20px;
}
.icon_image:hover {
    cursor: pointer;
}
@media screen and (max-width: 992px) {
    .vertical-menu {
        height: 100vh;
        top: 20px;
    }
}
</style>
