export const tenantMenuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: "/",
    },
    {
        id: 2,
        label: "Circulars",
        icon: "ri-book-2-line",
        link: "/circulars",
    },

    {
        id: 3,
        label: "Maintenance",
        icon: "ri-file-settings-line",
        link: "/new-maintenance",
    },

    {
        id: 5,
        label: "Landscape",
        icon: "ri-plant-line",
        link: "/landscaping",
    },

    {
        id: 6,
        label: "Security Deposit Refund",
        icon: "ri-refund-2-line",
        link: "/deposit-refund",
    },
    {
        id: 7,
        label: "Trash Bin",
        icon: "ri-delete-bin-7-fill",
        link: "/trash-bin",
    },
    {
        id: 8,
        label: "Barrier Remote Control",
        icon: "ri-remote-control-2-line",
        link: "/barrier-control",
    },
    {
        id: 9,
        label: "Commercial Modification",
        icon: "ri-home-gear-fill",
        link: "/commerical-mod",
    },

    {
        id: 10,
        label: "Refernce Docs",
        icon: "ri-sticky-note-line",
        link: "/",
    },
];
