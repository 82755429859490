export const menuItems = [
    {
        id: 2,
        label: "menuitems.dashboard.text",
        icon: "ri-dashboard-line",
        link: "/",
    },
    {
        id: 205,
        label: "Projects",
        icon: "ri-pencil-ruler-2-line",
        link: "/projects",
        groups: ["Work Assigner", "Test"],
    },
    // {
    //     id: 26,
    //     label: "Payment Links",
    //     icon: "ri-exchange-dollar-fill",
    //     link: "/",
    //     subItems: [
    //         {
    //             id: 308,
    //             label: "Create Payment Link",
    //             link: "/payment-links",
    //         },
    //         {
    //             id: 308,
    //             label: "List",
    //             link: "/payment-links-list",
    //         },
    //     ],
    // },
    {
        id: 3,
        label: "Circulars",
        icon: "ri-newspaper-line",
        link: "/",

        subItems: [
            {
                id: 106,
                label: "Create Circular",
                link: "/create-circular",
            },
            {
                id: 107,
                label: "List",
                link: "/circulars",
            },
        ],
    },
    {
        id: 4,
        label: "User Registration",
        icon: "ri-user-add-line",
        subItems: [
            {
                id: 5,
                label: "Register",
                link: "/registration",
            },
            {
                id: 6,
                label: "List",
                link: "/users",
            },
        ],
    },
    {
        id: 7,
        label: "Visitor Logs",
        icon: "ri-user-line",
        subItems: [
            {
                id: 8,
                label: "Create New Log",
                link: "/create-log",
            },
            {
                id: 9,
                label: "List",
                link: "/log-list",
            },
        ],
    },
    {
        id: 10,
        label: "Mortgage",
        icon: "ri-home-smile-2-line",
        link: "/mortgage-list",
    },
    {
        id: 11,
        label: "Amendment",
        icon: "ri-file-paper-2-line",
        link: "/amendments",
    },
    {
        id: 12,
        label: "Maintenance",
        icon: "ri-file-settings-line",
        link: "/maintenance-list",
    },

    {
        id: 13,
        label: "Immigration",
        icon: "ri-flight-takeoff-line",
        link: "/immigration-list",
    },
    {
        id: 14,
        label: "Title Deed",
        icon: "ri-pages-fill",
        link: "/titledeed-list",
    },
    {
        id: 15,
        label: "Interim Registration",
        icon: "ri-links-fill",
        link: "/interim-list",
    },
    {
        id: 16,
        label: "Home Improvement",
        icon: "ri-home-gear-fill",
        link: "/home-improvements",
    },
    {
        id: 17,
        label: "Landscape",
        icon: "ri-landscape-line",
        link: "/landscape-list",
    },
    {
        id: 18,
        label: "Security Deposit Refund",
        icon: "ri-coins-line",
        link: "/refund-list",
    },
    {
        id: 19,
        label: "Noc Revalidation",
        icon: "ri-hand-coin-line",
        link: "/noc-list",
    },
    {
        id: 20,
        label: "Villa As-Built Drawing ",
        icon: " ri-map-2-line",
        link: "/drawings-list",
    },
    {
        id: 21,
        label: "Trash Bin",
        icon: "ri-delete-bin-7-fill",
        link: "/trash-bin-list",
    },
    {
        id: 22,
        label: "Commerical Modification",
        icon: "ri-home-gear-fill",
        link: "/modification-list",
    },
    {
        id: 23,
        label: "Under Warranty Units",
        icon: "ri-calendar-2-line",
        link: "/",
        subItems: [
            {
                id: 306,
                label: "Under Warranty Unit List",
                link: "/under-warranty-list",
            },
            {
                id: 307,
                label: "Create New Unit",
                link: "/new-under-warranty",
            },
        ],
    },
    {
        id: 24,
        label: "Barrier Remote Control",
        icon: "ri-remote-control-2-line",
        link: "/barrier-control-list",
    },
    {
        id: 25,
        label: "Reference Docs",
        icon: "ri-sticky-note-line",
        link: "/",
    },
];
